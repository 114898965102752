import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme.js';
import Layout from './components/Layout.js';
import Footer from './components/Footer'; // Import the Footer component
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './components/Register';
import LicenseeForm from './pages/LicenseeForm';
import LicenseeList from './pages/LicenseeList';
import LicenseeServices from './pages/LicenseeServices.js';
import GenerateDocuments from './pages/GenerateDocuments';
import Dashboard from './pages/Dashboard';
import MovingOutList from './pages/MovingOutList';  
import PrivateRoute from './components/PrivateRoute'; 
import ReportIssue from './pages/ReportIssue';
import PayRent from './pages/PayRent';
import PaymentConfirmation from './pages/PaymentConfirmation';
import GetDocuments from './pages/GetDocuments';
import GiveNotice from './pages/GiveNotice';
import SuccessPage from './pages/SuccessPage';  
import Profile from './pages/Profile';
import AdminRentPage from './pages/AdminRentPage';
import TrackingProfit from './pages/TrackingProfit';
import ReportIssueDetail from './pages/ReportIssueDetailPage.js';
import VideoManagement from './pages/VideoManagement.js';
import RegistrationSuccess from './components/RegistrationSuccess';
import TrackLocation from './components/TrackLocation';
import PrivacyPolicy from './pages/PrivacyPolicy';
import HouseRules from './pages/HouseRules';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/registration-success" element={<RegistrationSuccess />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          {/* Profile accessible by all authenticated users */}
          <Route element={<PrivateRoute />}> 
            <Route path="/profile" element={<Profile />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Route>

          {/* Admin and Viewings routes for Licensees, Add Licensee, and Moving Out List */}
          <Route element={<PrivateRoute allowedRoles={['admin', 'viewings', 'god']} />}>
            <Route path="/licensees" element={<LicenseeList />} />
            <Route path="/add-licensee" element={<LicenseeForm />} />
            <Route path="/moving-out-list" element={<MovingOutList />} />
            <Route path="/generate-documents" element={<GenerateDocuments />} />
            <Route path="/track/:issueId" element={<TrackLocation />} />
          </Route>

          <Route element={<PrivateRoute allowedRoles={['user']} />}>
            <Route path="/licensee-services" element={<LicenseeServices />} />
          </Route>

          {/* Licensee (user) routes */}
          <Route element={<PrivateRoute allowedRoles={['user']} />}>
            <Route path="/report-issue" element={<ReportIssue />} />
            <Route path="/report/:category" element={<ReportIssueDetail />} />
            <Route path="/pay-rent" element={<PayRent />} />
            <Route path="/payment-confirmation" element={<PaymentConfirmation />} />
            <Route path="/get-documents" element={<GetDocuments />} />
            <Route path="/give-notice" element={<GiveNotice />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/track/:issueId" element={<TrackLocation />} />
            <Route path="/house-rules" element={<HouseRules />} />
          </Route>

          {/* Dashboard route for both Maintenance and Admin roles */}
          <Route element={<PrivateRoute allowedRoles={['maintenance', 'admin', 'god']} />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/video-management" element={<VideoManagement />} />
          </Route>

          {/* Admin Rent Page (Only for admins) */}
          <Route element={<PrivateRoute allowedRoles={['admin', 'god']} />}>
            <Route path="/admin-rent-management" element={<AdminRentPage />} />
            <Route path="/track/:issueId" element={<TrackLocation />} />
          </Route>

          {/* Only for Kevin) */}
          <Route element={<PrivateRoute allowedRoles={['god']} />}>
            <Route path="/tracking-profit" element={<TrackingProfit />} />
          </Route>
        </Routes>
        
        {/* Add the Footer here to ensure it's visible on every page */}
        <Footer />
      </Layout>
    </ThemeProvider>
  );
}

export default App;
