import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, updateDoc, doc, setDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Typography, Select, MenuItem, FormControl, Container,
  CircularProgress, TextField, Box, Button, Tooltip, TableSortLabel,
  FormControlLabel, FormLabel, FormGroup, Checkbox, Snackbar, Alert,
  Dialog, DialogActions, DialogContent, DialogTitle, Grid
} from '@mui/material';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { getFunctions, httpsCallable } from "firebase/functions";
import './Dashboard.css';

function Dashboard() {
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updatingIssueId, setUpdatingIssueId] = useState(null);
  const [updatingAssigneeId, setUpdatingAssigneeId] = useState(null);
  const [trackingIssueId, setTrackingIssueId] = useState(null);  // Track which issue is being tracked
  const [search, setSearch] = useState('');
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
  const [groupBy, setGroupBy] = useState('property');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [maintenanceUsers, setMaintenanceUsers] = useState([]);
  const [statusFilter, setStatusFilter] = useState(['Reported', 'In Progress']);
  const [priorityFilter, setPriorityFilter] = useState([]);
  const [propertyFilter, setPropertyFilter] = useState('');
  const [assigneeFilter, setAssigneeFilter] = useState('');
  const [issueTypeFilter, setIssueTypeFilter] = useState('');
  const [dateRangeFilter, setDateRangeFilter] = useState({ startDate: '', endDate: '' });
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [customMessage, setCustomMessage] = useState('');
  const [selectedLicensee, setSelectedLicensee] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [sendTrackingLink, setSendTrackingLink] = useState(false); 
  const [isTrackingActive, setIsTrackingActive] = useState(false);  
  const [showFilters, setShowFilters] = useState(false); 

  
  // Handle status update
  const handleUpdateStatus = async (issueId, status) => {
    setUpdatingIssueId(issueId);

    try {
      await updateDoc(doc(db, 'maintenance', issueId), { status });
      setIssues(issues.map(issue => (issue.id === issueId ? { ...issue, status } : issue)));
    } catch (error) {
      setError('Failed to update issue status.');
    } finally {
      setUpdatingIssueId(null);
    }
  };
  // Function to extract the first line of an address (before the first comma)
  const extractFirstLine = (address) => {
    if (!address) return '';
    return address.split(',')[0]; // Splits the string by comma and returns the first part
  };

  // Handle assignee update
  const handleAssignUser = async (issueId, userId) => {
    setUpdatingAssigneeId(issueId);
  
    try {
      await updateDoc(doc(db, 'maintenance', issueId), { assignee: userId });
      setIssues(issues.map(issue => (issue.id === issueId ? { ...issue, assignee: userId } : issue)));
      
      // Set selected issue and open the dialog for email after assigning the user
      const selected = issues.find(issue => issue.id === issueId);
      setSelectedIssue(selected);
      setOpenDialog(true);  // Open the email dialog
  
    } catch (error) {
      setError('Failed to assign user.');
    } finally {
      setUpdatingAssigneeId(null);
    }
  };
  

  // Handle priority update
  const handleUpdatePriority = async (issueId, priority) => {
    try {
      await updateDoc(doc(db, 'maintenance', issueId), { priority });
      setIssues(issues.map(issue => (issue.id === issueId ? { ...issue, priority } : issue)));
    } catch (error) {
      setError('Failed to update issue priority.');
    }
  };

  // Fetch the user's reference code when the dialog opens
  useEffect(() => {
    const fetchLicenseeFromUser = async () => {
      if (selectedIssue && selectedIssue.referenceCode) {
        console.log('Fetching reference code for issue:', selectedIssue.referenceCode);

        try {
          // Fetch the licensee based on the issue's reference code
          const licenseeRef = query(collection(db, 'licensees'), where('REFERENCE CODE', '==', selectedIssue.referenceCode));
          const licenseeSnapshot = await getDocs(licenseeRef);

          if (!licenseeSnapshot.empty) {
            const licenseeData = licenseeSnapshot.docs[0]?.data();
            console.log('Licensee fetched:', licenseeData);
            setSelectedLicensee(licenseeData);
          } else {
            console.log('No licensee found for reference code:', selectedIssue.referenceCode);
            setSelectedLicensee(null);  // Handle no licensee found
          }
        } catch (error) {
          console.error('Error fetching licensee:', error);
          setError('Failed to fetch the licensee.');
        }
      }
    };

    if (openDialog) {
      fetchLicenseeFromUser();  // Fetch only when dialog opens
    }
  }, [openDialog, selectedIssue]);

  // Fetch issues and listen for real-time updates
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'maintenance'), (snapshot) => {
      const issuesList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      const issuesWithProperty = issuesList.map(issue => ({
        ...issue,
        property: issue.property || issue.house || 'Unknown'
      }));

      setIssues(issuesWithProperty);
      setLoading(false);
    }, (error) => {
      setError('Failed to load issues');
    });

    return () => unsubscribe();
  }, []);

  // Fetch maintenance users
  useEffect(() => {
    const fetchMaintenanceUsers = async () => {
      try {
        const q = query(collection(db, 'users'), where('role', '==', 'maintenance'));
        const usersSnapshot = await onSnapshot(q, (snapshot) => {
          const maintenanceUsersList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setMaintenanceUsers(maintenanceUsersList);
        });
      } catch (error) {
        setError('Failed to load maintenance users');
      }
    };

    fetchMaintenanceUsers();
  }, []);

  // Handle search input changes with debounce
  const handleSearch = debounce((searchValue) => {
    setSearch(searchValue);
  }, 300);

  // Filter issues based on search, status, priority, property, issue type, date range, and assignee filters
  useEffect(() => {
    setFilteredIssues(
      issues.filter(issue => {
        const issueDate = issue.createdAt ? issue.createdAt.toDate() : null;

        const isInDateRange = dateRangeFilter.startDate && dateRangeFilter.endDate
          ? issueDate >= new Date(dateRangeFilter.startDate) && issueDate <= new Date(dateRangeFilter.endDate)
          : true;

        return (
          (issue.property ? issue.property.toLowerCase() : '').includes(search.toLowerCase()) ||
          (issue.issueType ? issue.issueType.toLowerCase() : '').includes(search.toLowerCase()) ||
          (issue.description ? issue.description.toLowerCase() : '').includes(search.toLowerCase()) ||
          (issue.location ? issue.location.toLowerCase() : '').includes(search.toLowerCase()) ||
          (issue.status ? issue.status.toLowerCase() : '').includes(search.toLowerCase())
        ) &&
        statusFilter.includes(issue.status || 'Reported') &&
        (priorityFilter.length === 0 || priorityFilter.includes(issue.priority || 'Medium')) &&
        (propertyFilter === '' || issue.property === propertyFilter) &&
        (issueTypeFilter === '' || issue.issueType === issueTypeFilter) &&
        (assigneeFilter === '' || issue.assignee === assigneeFilter) &&
        isInDateRange
      })
    );
  }, [search, issues, statusFilter, priorityFilter, propertyFilter, issueTypeFilter, assigneeFilter, dateRangeFilter]);

  // Handle tracking of an issue
  const handleTracker = async (issue) => {
    const issueRef = doc(db, 'maintenance', issue.id);
    
    try {
      // Toggle tracking
      const newTrackingStatus = trackingIssueId !== issue.id || !isTrackingActive;
      
      // Set expiration time (e.g., 30 minutes from now)
      const expirationTime = new Date();
      expirationTime.setMinutes(expirationTime.getMinutes() + 60);
      
      // Update Firestore with the tracking status and expiration time
      await updateDoc(issueRef, {
        isTrackingActive: newTrackingStatus,
        trackingExpirationTime: newTrackingStatus ? expirationTime : null,  // Set expiration if active
      });
  
      // Update local state based on the toggle
      if (newTrackingStatus) {
        setSelectedIssue(issue);
        setTrackingIssueId(issue.id);  // Set the new tracking issue ID
      } else {
        setTrackingIssueId(null);  // Clear the tracking issue ID
      }
      setIsTrackingActive(newTrackingStatus);  // Update the tracking status
    } catch (error) {
      setError('Failed to update tracking status.');
    }
  };
  

  // Generate tracking link
  const generateTrackingLink = (issueId) => {
    return `/track/${issueId}`;
  };

  const handleSendEmail = async () => {
    try {
      const functions = getFunctions();
      functions.region = "europe-west1";
      const sendReminderEmailCallable = httpsCallable(functions, 'sendReminderEmail');
  
      if (!selectedLicensee.EMAIL || !customMessage.trim()) {
        throw new Error('Invalid email or message content.');
      }
  
      let emailMessage = customMessage;
  
      if (sendTrackingLink) {
        console.log("Sending tracking link...");
  
        // Set the expiration time for 1 hour from now
        const expirationTime = new Date();
        expirationTime.setHours(expirationTime.getHours() + 1);
  
        // Log expiration time for debugging
        console.log("Expiration Time:", expirationTime);
  
        // Save the tracking information to Firestore with an expiration time
        const trackingRef = doc(db, 'tracking', selectedIssue.id);
        
        // Use reference code instead of userId
        if (selectedLicensee && selectedLicensee['REFERENCE CODE']) {
          console.log("Reference Code:", selectedLicensee['REFERENCE CODE']);
          await setDoc(trackingRef, {
            issueId: selectedIssue.id,
            referenceCode: selectedLicensee['REFERENCE CODE'],  // Use reference code instead of userId
            locationTracking: selectedIssue.locationTracking,
            createdAt: new Date(),
            expiresAt: expirationTime,  // Ensure expirationTime is defined
          });
          console.log("Tracking document saved successfully.");
  
          // Generate the tracking link
          const trackingLink = `${window.location.origin}/track/${selectedIssue.id}`;
          emailMessage += `\nYou can track the maintenance person here: ${trackingLink}\nNote: This link will expire in 1 hour.`;
        } else {
          console.error("Error: selectedLicensee or referenceCode is undefined.");
        }
      }
  
      // Email content
      const emailData = {
        email: selectedLicensee.EMAIL,
        subject: 'Maintenance Personnel Tracking',
        message: emailMessage,
      };
  
      console.log("Sending email with data:", emailData);
  
      const response = await sendReminderEmailCallable(emailData);
  
      if (response.data.success) {
        console.log("Email sent successfully.");
        setOpenDialog(false);
        setSnackbarOpen(true);
        setCustomMessage('');  // Clear the message after successful send
      } else {
        throw new Error('Error sending email');
      }
    } catch (error) {
      console.error('Failed to send the email or tracking information:', error);
      setError('Failed to send the email.');
      setSnackbarOpen(true);
    }
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedIssues = [...filteredIssues].sort((a, b) => {
    const aValue = a[sortConfig.key] ? a[sortConfig.key] : '';
    const bValue = b[sortConfig.key] ? b[sortConfig.key] : '';

    if (sortConfig.direction === 'asc') {
      return aValue > bValue ? 1 : -1;
    }
    return aValue < bValue ? 1 : -1;
  });

  const groupIssues = (issues) => {
    return issues.reduce((group, issue) => {
      const key = groupBy === 'property' ? issue.property || 'Unknown' : issue.status || 'Reported';
      if (!group[key]) {
        group[key] = [];
      }
      group[key].push(issue);
      return group;
    }, {});
  };

  const sortByStatus = (issues) => {
    const statusOrder = { 'Reported': 1, 'In Progress': 2, 'Resolved': 3 };
    return issues.sort((a, b) => {
      const aStatus = statusOrder[a.status] || 4;
      const bStatus = statusOrder[b.status] || 4;
      return aStatus - bStatus;
    });
  };

  const groupedIssues = groupIssues(sortedIssues);

  if (loading) {
    return <Typography variant="h6">Loading data...</Typography>;
  }

  const toggleCardContent = (group, index) => {
    setIssues(issues.map((issue, i) => (
      issue.id === groupedIssues[group][index].id ? { ...issue, isOpen: !issue.isOpen } : issue
    )));
  };

  const handleStatusFilterChange = (status, isChecked) => {
    setStatusFilter(prevFilter => {
      if (isChecked) {
        return [...prevFilter, status];
      } else {
        return prevFilter.filter(s => s !== status);
      }
    });
  };

  const handlePriorityFilterChange = (priority, isChecked) => {
    setPriorityFilter(prevFilter => {
      if (isChecked) {
        return [...prevFilter, priority];
      } else {
        return prevFilter.filter(p => p !== priority);
      }
    });
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Maintenance
      </Typography>

      <Link to="/video-management" style={{ textDecoration: 'none' }}>
        <Button variant="contained" color="primary" sx={{ mb: 3 }}>
          Video / Photo Management
        </Button>
      </Link>

{/* Filters Section */}
{isMobile ? (
              <>
                <Button
                  variant="contained"
                  onClick={() => setShowFilters(!showFilters)}
                  sx={{ marginBottom: 2 }}
                >
                  {showFilters ? 'Hide Filters' : 'Show Filters'}
                </Button>
                
                {showFilters && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Filter by Property"
                        variant="outlined"
                        fullWidth
                        value={propertyFilter}
                        onChange={(e) => setPropertyFilter(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Filter by Issue Type"
                        variant="outlined"
                        fullWidth
                        value={issueTypeFilter}
                        onChange={(e) => setIssueTypeFilter(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Filter by Status</FormLabel>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={statusFilter.includes('Reported')}
                                onChange={(e) => handleStatusFilterChange('Reported', e.target.checked)}
                              />
                            }
                            label="Reported"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={statusFilter.includes('In Progress')}
                                onChange={(e) => handleStatusFilterChange('In Progress', e.target.checked)}
                              />
                            }
                            label="In Progress"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={statusFilter.includes('Resolved')}
                                onChange={(e) => handleStatusFilterChange('Resolved', e.target.checked)}
                              />
                            }
                            label="Resolved"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Filter by Priority</FormLabel>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={priorityFilter.includes('Low')}
                                onChange={(e) => handlePriorityFilterChange('Low', e.target.checked)}
                              />
                            }
                            label="Low"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={priorityFilter.includes('Medium')}
                                onChange={(e) => handlePriorityFilterChange('Medium', e.target.checked)}
                              />
                            }
                            label="Medium"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={priorityFilter.includes('High')}
                                onChange={(e) => handlePriorityFilterChange('High', e.target.checked)}
                              />
                            }
                            label="High"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              </>
) : (

    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <TextField
          data-testid="search-input"
          label="Search Issues"
          variant="outlined"
          fullWidth
          sx={{ height: '56px' }}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search by property, issue type, description, or status"
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <FormControl fullWidth sx={{ height: '56px' }}>
          <Select
            data-testid="group-by-select"
            value={groupBy}
            onChange={(e) => setGroupBy(e.target.value)}
            fullWidth
            sx={{ height: '56px' }}
          >
            <MenuItem value="property">Group by Property</MenuItem>
            <MenuItem value="status">Group by Status</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          label="Filter by Property"
          variant="outlined"
          fullWidth
          sx={{ height: '56px' }}
          onChange={(e) => setPropertyFilter(e.target.value)}
          placeholder="Enter property name"
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          label="Filter by Issue Type"
          variant="outlined"
          fullWidth
          sx={{ height: '56px' }}
          onChange={(e) => setIssueTypeFilter(e.target.value)}
          placeholder="Enter issue type"
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          select
          label="Assignee"
          value={assigneeFilter}
          onChange={(e) => setAssigneeFilter(e.target.value)}
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}  // Ensures consistent label positioning
        >
          <MenuItem value="">
            <em>Unassigned</em>
          </MenuItem>
          {maintenanceUsers.map(user => (
            <MenuItem key={user.id} value={user.id}>
              {user.fullName || user.email}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="Start Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          fullWidth
          sx={{ height: '56px' }}
          onChange={(e) => setDateRangeFilter(prev => ({ ...prev, startDate: e.target.value }))}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="End Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          fullWidth
          sx={{ height: '56px' }}
          onChange={(e) => setDateRangeFilter(prev => ({ ...prev, endDate: e.target.value }))}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl component="fieldset" fullWidth sx={{ height: '56px' }}>
          <FormLabel component="legend">Filter by Status</FormLabel>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={statusFilter.includes('Reported')}
                  onChange={(e) => handleStatusFilterChange('Reported', e.target.checked)}
                />
              }
              label="Reported"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={statusFilter.includes('In Progress')}
                  onChange={(e) => handleStatusFilterChange('In Progress', e.target.checked)}
                />
              }
              label="In Progress"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={statusFilter.includes('Resolved')}
                  onChange={(e) => handleStatusFilterChange('Resolved', e.target.checked)}
                />
              }
              label="Resolved"
            />
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl component="fieldset" fullWidth sx={{ height: '56px' }}>
          <FormLabel component="legend">Filter by Priority</FormLabel>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={priorityFilter.includes('Low')}
                  onChange={(e) => handlePriorityFilterChange('Low', e.target.checked)}
                />
              }
              label="Low"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={priorityFilter.includes('Medium')}
                  onChange={(e) => handlePriorityFilterChange('Medium', e.target.checked)}
                />
              }
              label="Medium"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={priorityFilter.includes('High')}
                  onChange={(e) => handlePriorityFilterChange('High', e.target.checked)}
                />
              }
              label="High"
            />
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  )}


      {/* Table for Issues */}
      <Box mt={4}>
        {Object.keys(groupedIssues).map((group) => (
          <Box key={group} mt={4}>
            {/* <Typography variant="h5" gutterBottom>
              {group}
            </Typography> */}

            {!isMobile && (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {groupBy === 'status' && <TableCell>Property</TableCell>} 
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.key === 'createdAt'}
                          direction={sortConfig.direction}
                          onClick={() => handleSort('createdAt')}
                        >
                          Date
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.key === 'issueType'}
                          direction={sortConfig.direction}
                          onClick={() => handleSort('issueType')}
                        >
                          Issue Type
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Media</TableCell> 
                      {groupBy === 'property' && <TableCell>Status</TableCell>}
                      {groupBy === 'property' && <TableCell>Assignee</TableCell>}
                      <TableCell>Priority</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
  {sortByStatus(groupedIssues[group]).map((issue) => (
    <TableRow key={issue.id}>
      {groupBy === 'status' && <TableCell>{issue.property}</TableCell>} 

      {/* Date */}
      <TableCell>{issue.createdAt ? format(issue.createdAt.toDate(), 'yyyy-MM-dd') : 'No Date'}</TableCell>

      {/* Issue Type */}
      <TableCell>{issue.issueType}</TableCell>

      {/* Description */}
      <TableCell>
        <Tooltip title={issue.description}>
          <span style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
            maxWidth: '150px'
          }}>
            {issue.description}
          </span>
        </Tooltip>
      </TableCell>

      {/* Location */}
      <TableCell>{extractFirstLine(issue.location)}</TableCell>

      {/* Media */}
      <TableCell>
        {issue.fileUrl ? (
          <>
            {issue.fileUrl.includes('.mp4') || issue.fileUrl.includes('.webm') ? (
              <video width="150" controls>
                <source src={issue.fileUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img src={issue.fileUrl} alt="Uploaded media" width="150" />
            )}
            <Box mt={1}>
              <Button variant="outlined" href={issue.fileUrl} target="_blank" download>
                Download
              </Button>
            </Box>
          </>
        ) : (
          <Typography>No media</Typography>
        )}
      </TableCell>

      {/* Status */}
      <TableCell>
        <FormControl fullWidth variant="outlined" size="small">
          {updatingIssueId === issue.id ? (
            <CircularProgress size={24} />
          ) : (
            <Select
              value={issue.status || 'Reported'}
              onChange={(e) => handleUpdateStatus(issue.id, e.target.value)}
            >
              <MenuItem value="Reported">Reported</MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Resolved">Resolved</MenuItem>
            </Select>
          )}
        </FormControl>
      </TableCell>

      {/* Assignee */}
      <TableCell>
        <FormControl fullWidth variant="outlined" size="small">
          {updatingAssigneeId === issue.id ? (
            <CircularProgress size={24} />
          ) : (
            <Select
              value={issue.assignee || ''}
              onChange={(e) => handleAssignUser(issue.id, e.target.value)}
              displayEmpty
            >
              <MenuItem value=""><em>Unassigned</em></MenuItem>
              {maintenanceUsers.map(user => (
                <MenuItem key={user.id} value={user.id}>{user.fullName || user.email}</MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </TableCell>

      {/* Priority */}
      <TableCell>
        <FormControl fullWidth variant="outlined" size="small">
          <Select
            value={issue.priority || 'Medium'}
            onChange={(e) => handleUpdatePriority(issue.id, e.target.value)}
          >
            <MenuItem value="Low">Low</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="High">High</MenuItem>
          </Select>
        </FormControl>
      </TableCell>

      {/* Actions */}
      <TableCell>
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={1}>
          <Button
            variant="contained"
            size="small"
            color={trackingIssueId === issue.id && isTrackingActive ? "secondary" : "primary"}
            onClick={() => handleTracker(issue)}
          >
            {trackingIssueId === issue.id && isTrackingActive ? "TURN TRACKING OFF" : "TURN TRACKING ON"}
          </Button>

          {trackingIssueId === issue.id && isTrackingActive && (
            <Link to={generateTrackingLink(issue.id)} target="_blank">
              <Button variant="contained" color="info" size="small" sx={{ ml: 1 }}>
                View Map
              </Button>
            </Link>
          )}
        </Box>
      </TableCell>
    </TableRow>
  ))}
</TableBody>


    </Table>
  </TableContainer>
)}

{isMobile && (
  <Box>

    {showFilters && (
      <Box sx={{ marginBottom: '20px' }}>
        {/* Assignee Filter */}
        <FormControl fullWidth variant="outlined" size="small" sx={{ marginBottom: '10px' }}>
          <Select
            value={assigneeFilter}
            onChange={(e) => setAssigneeFilter(e.target.value)}
            displayEmpty
          >
            <MenuItem value=""><em>Unassigned</em></MenuItem>
            {maintenanceUsers.map(user => (
              <MenuItem key={user.id} value={user.id}>{user.fullName || user.email}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Status Filter */}
        <FormControl component="fieldset" fullWidth sx={{ marginBottom: '10px' }}>
          <FormLabel component="legend">Filter by Status</FormLabel>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={statusFilter.includes('Reported')}
                  onChange={(e) => handleStatusFilterChange('Reported', e.target.checked)}
                />
              }
              label="Reported"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={statusFilter.includes('In Progress')}
                  onChange={(e) => handleStatusFilterChange('In Progress', e.target.checked)}
                />
              }
              label="In Progress"
            />
          </FormGroup>
        </FormControl>
      </Box>
    )}

    {/* Render simplified card view for issues */}
    {sortByStatus(groupedIssues[group]).map((issue, index) => (
      <Box
        className={`card-container ${issue.isOpen ? 'open' : ''}`}
        key={issue.id}
        onClick={() => toggleCardContent(group, index)}
        sx={{
          border: '1px solid #ddd',
          borderRadius: '8px',
          padding: '10px',
          marginBottom: '10px',
        }}
      >
        <Box className="card-header">
          <Typography className="card-title" variant="h6">{issue.issueType}</Typography>
          <Typography variant="body2">{issue.createdAt ? format(issue.createdAt.toDate(), 'yyyy-MM-dd') : 'No Date'}</Typography>
        </Box>
        <Box className="card-content">
          <Typography variant="body2">Description: {issue.description}</Typography>
          <Typography variant="body2">Location: {extractFirstLine(issue.location)}</Typography>
          <Typography variant="body2">Priority: {issue.priority || 'Medium'}</Typography>
          {issue.fileUrl && (
            <Box mt={2}>
              {issue.fileUrl.includes('.mp4') || issue.fileUrl.includes('.webm') ? (
                <video width="100%" controls>
                  <source src={issue.fileUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={issue.fileUrl} alt="Uploaded media" width="100%" />
              )}
            </Box>
          )}
          <Button
            variant="contained"
            size="small"
            sx={{
              fontSize: '0.75rem',
              padding: '4px 8px',
              textTransform: 'none',
              marginTop: '10px',
            }}
            onClick={() => handleTracker(issue)}
          >
            {trackingIssueId === issue.id && isTrackingActive ? "TURN TRACKING OFF" : "TURN TRACKING ON"}
          </Button>

          {trackingIssueId === issue.id && isTrackingActive && (
            <Link to={generateTrackingLink(issue.id)} target="_blank">
              <Button variant="contained" color="info" size="small" sx={{ mt: 1 }}>
                View Map
              </Button>
            </Link>
          )}
        </Box>
      </Box>
    ))}
  </Box>
)}
    </Box>
  ))}
</Box>

      {/* Error Handling Snackbar */}
      {error && (
        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}

    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>Tracking Information</DialogTitle>
      <DialogContent>
        {selectedLicensee ? (
          <>
            <Typography gutterBottom>
              <strong>Sending to:</strong>
            </Typography>
            <Typography>
              <strong>Name:</strong> {selectedLicensee.NAME || 'N/A'}
            </Typography>
            <Typography>
              <strong>Email:</strong> {selectedLicensee.EMAIL || 'N/A'}
            </Typography>
          </>
        ) : (
          <Typography>No licensee information available.</Typography>
        )}

        {/* Checkbox to include the tracking map link */}
        <FormControlLabel
          control={
            <Checkbox
              checked={sendTrackingLink}
              onChange={(e) => setSendTrackingLink(e.target.checked)}
            />
          }
          label="Include tracking link to maintenance personnel's location"
        />

        {sendTrackingLink && (
          <Link to={generateTrackingLink(selectedIssue?.id)} target="_blank">
            <Button variant="contained" color="primary">
              View Map
            </Button>
          </Link>
        )}

        {/* Email sending section */}
        <TextField
          label="Custom Message"
          multiline
          rows={4}
          value={customMessage}
          onChange={(e) => setCustomMessage(e.target.value)}
          fullWidth
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)} color="secondary">Cancel</Button>
        <Button onClick={handleSendEmail} color="primary" variant="contained">
          Send Email
        </Button>
      </DialogActions>
    </Dialog>

    </Container>
  );
}

export default Dashboard;
