import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Box, LinearProgress, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ref, uploadBytesResumable, getDownloadURL, listAll } from 'firebase/storage'; 
import { doc, collection, addDoc, getDoc, query, where, getDocs } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { useAuth } from '../contexts/AuthContext';

function ReportDetail() {
  const { category } = useParams();
  const { currentUser } = useAuth();
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [uploadStatus, setUploadStatus] = useState('idle');
  const [fileUrl, setFileUrl] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [property, setProperty] = useState(''); 
  const [referenceCode, setReferenceCode] = useState(''); 
  const [loadingProperty, setLoadingProperty] = useState(true); 
  const [videos, setVideos] = useState([]); // State for storing fetched videos
  const [watchedVideos, setWatchedVideos] = useState(false); // State for checkbox

  // Fetch the current user's reference code and associated property
  useEffect(() => {
    const fetchUserProperty = async () => {
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const userReferenceCode = userData.referenceCode;

            setReferenceCode(userReferenceCode); 

            const licenseeQuery = query(
              collection(db, 'licensees'),
              where('REFERENCE CODE', '==', userReferenceCode)
            );
            const licenseeSnapshot = await getDocs(licenseeQuery);

            if (!licenseeSnapshot.empty) {
              const licenseeData = licenseeSnapshot.docs[0].data();
              const userProperty = licenseeData.ADDRESS || 'Unknown Property'; 
              setProperty(userProperty);
            } else {
              setMessage('No matching property found for your reference code.');
            }
          } else {
            setMessage('No user data found.');
          }
        } catch (err) {
          console.error('Error fetching property:', err);
          setMessage('Failed to fetch property data.');
        } finally {
          setLoadingProperty(false);
        }
      }
    };

    fetchUserProperty();
  }, [currentUser]);

  // Fetch videos for the selected property and category, and "All houses"
  useEffect(() => {
    const fetchVideos = async () => {
      if (!property || !category) return;

      let videoUrls = [];
      try {
        // Fetch property-specific videos
        const propertyVideoPath = `videos/${property}/${category}`;
        const propertyVideoRef = ref(storage, propertyVideoPath);
        const propertyVideoList = await listAll(propertyVideoRef);
        const propertyUrls = await Promise.all(
          propertyVideoList.items.map(item => getDownloadURL(item))
        );
        videoUrls = [...videoUrls, ...propertyUrls];

        // Fetch videos from "All houses"
        const allHousesVideoPath = `videos/all-houses/${category}`;
        const allHousesVideoRef = ref(storage, allHousesVideoPath);
        const allHousesVideoList = await listAll(allHousesVideoRef);
        const allHousesUrls = await Promise.all(
          allHousesVideoList.items.map(item => getDownloadURL(item))
        );
        videoUrls = [...videoUrls, ...allHousesUrls];

        setVideos(videoUrls);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, [property, category]);

  const handleFileUpload = () => {
    if (file) {
      setUploadStatus('uploading');
      const fileRef = ref(storage, `maintenance/${Date.now()}-${file.name}`);
      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error('Error uploading media:', error);
          setUploadStatus('error');
          setMessage('Error uploading media. Please try again.');
        },
        async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          setFileUrl(url);
          setUploadStatus('success');
          setMessage('Media uploaded successfully!');
        }
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      setMessage('You must be logged in to report an issue.');
      return;
    }

    if (!property) {
      setMessage('Property information is missing.');
      return;
    }

    if (!watchedVideos) {
      setMessage('You must confirm you have watched the instructional videos before reporting the issue.');
      return;
    }

    if (!file) {
      setMessage('You must upload a photo or video of the problem.');
      return;
    }

    if (!fileUrl) {
      setMessage('Please upload the photo or video before submitting.');
      return;
    }

    try {
      const maintenanceDoc = {
        issueType: category,
        description,
        property, 
        location: property, 
        status: 'Reported',
        createdAt: new Date(),
        userId: currentUser.uid,
        referenceCode, 
        assignee: null,
        fileUrl, 
      };

      await addDoc(collection(db, 'maintenance'), maintenanceDoc);

      setMessage('Issue reported successfully!');
      setDescription('');
      setFile(null);
      setUploadProgress(0);
      setFileUrl(null);
    } catch (error) {
      console.error('Error reporting the issue:', error);
      setMessage('Failed to report the issue. Please try again.');
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setUploadStatus('idle');
    setMessage('');
    setUploadProgress(0);
  };

  return (
    <Container maxWidth="sm">
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          p: 3,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          boxShadow: 3,
          mt: 4,
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Report {category} Issue
        </Typography>

        {loadingProperty ? (
          <CircularProgress />
        ) : (
          <Typography variant="h6" align="center">
            Reporting for Property: {property}
          </Typography>
        )}

        {/* Display instructional videos */}
        {videos.length > 0 && (
          <Box>
            <Typography variant="h6" mt={3}>Instructional Videos:</Typography>
            {videos.map((videoUrl, index) => (
              <Box key={index} mt={2}>
                <video width="100%" controls>
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Box>
            ))}
          </Box>
        )}

        {/* Checkbox to confirm videos have been watched */}
        <FormControlLabel
          control={
            <Checkbox
              checked={watchedVideos}
              onChange={(e) => setWatchedVideos(e.target.checked)}
            />
          }
          label="I have watched the instructional videos"
        />

        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          multiline
          rows={4}
          fullWidth
          margin="normal"
          disabled={loadingProperty}
        />

        <Button
          variant="contained"
          component="label"
          fullWidth
          sx={{ mt: 2, mb: 2 }}
        >
          Select Photo/Video
          <input
            type="file"
            hidden
            onChange={handleFileChange}
            accept="image/*,video/*"
          />
        </Button>

        {file && (
          <Button
            variant="contained"
            fullWidth
            sx={{ mb: 2 }}
            onClick={handleFileUpload}
            disabled={uploadStatus === 'uploading' || uploadStatus === 'success'}
          >
            Upload Photo/Video
          </Button>
        )}

        {uploadStatus === 'uploading' && (
          <Box sx={{ mt: 2 }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
            <Typography variant="body2" align="center" sx={{ mt: 1 }}>
              Uploading... {Math.round(uploadProgress)}%
            </Typography>
          </Box>
        )}

        {uploadStatus === 'success' && (
          <Typography variant="body2" align="center" sx={{ mt: 2, color: 'green' }}>
            Media uploaded successfully!
          </Typography>
        )}

        {uploadStatus === 'error' && (
          <Typography variant="body2" align="center" sx={{ mt: 2, color: 'red' }}>
            Error uploading media. Please try again.
          </Typography>
        )}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          disabled={loadingProperty}
        >
          Submit
        </Button>

        {message && (
          <Typography variant="body1" align="center" sx={{ mt: 2, color: 'text.primary' }}>
            {message}
          </Typography>
        )}
      </Box>
    </Container>
  );
}

export default ReportDetail;
