import React, { useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import Papa from "papaparse";
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { Container, Typography, Button, CircularProgress, Checkbox, FormControlLabel, Link, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook

function PayRent() {
    const { currentUser } = useAuth();
    const [rentInfo, setRentInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [confirmPayment, setConfirmPayment] = useState(false);
    const [paymentPeriod, setPaymentPeriod] = useState(''); // Store payment period (e.g., "September 1st")
    const [region, setRegion] = useState("UK"); // You can adjust this based on user location
    const navigate = useNavigate(); // Initialize navigate

    // Function to capitalize only the first letter of the month
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    // Extract month and date from the file name (e.g., "SEPTEMBER_1st.csv" => "September 1st")
    const extractPaymentPeriod = (fileName) => {
        const [month, day] = fileName.replace('.csv', '').split('_');
        return `${capitalizeFirstLetter(month.toLowerCase())} ${day}`;
    };

    // Function to parse the CSV and find the user's data by reference code
    const parseCSVAndFindUser = (csvText, referenceCode) => {
        return new Promise((resolve, reject) => {
            Papa.parse(csvText, {
                header: true,
                skipEmptyLines: true,
                complete: (result) => {
                    const userRentData = result.data.find(row => row['Ref Code'] === referenceCode);
                    if (userRentData) {
                        resolve(userRentData);
                    } else {
                        resolve(null); // If not found, return null
                    }
                },
                error: (error) => {
                    reject(error);
                }
            });
        });
    };

    useEffect(() => {
        const fetchRentData = async () => {
            if (!currentUser) return;

            try {
                // Fetch the user’s reference code from Firestore
                const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    const referenceCode = userData.referenceCode;

                    // Fetch the current date and decide which payment runs to use
                    const today = new Date();
                    const dayOfMonth = today.getDate();

                    // Fetch the latest and previous CSV files from Firebase Storage
                    const storage = getStorage();
                    const baseRef = ref(storage, 'Open Banking Tracking/');
                    const yearFolders = await listAll(baseRef);
                    const latestYear = yearFolders.prefixes.pop().name;

                    const monthFolders = await listAll(ref(storage, `Open Banking Tracking/${latestYear}`));
                    const latestMonth = monthFolders.prefixes.pop().name;
                    const previousMonth = monthFolders.prefixes.pop().name; // Get the previous month

                    const formattedLatestMonthFolder = capitalizeFirstLetter(latestMonth);
                    const formattedPreviousMonthFolder = capitalizeFirstLetter(previousMonth);

                    let currentFileRef, previousFileRef;

                    if (dayOfMonth > 15) {
                        // If we're in the second half of the month
                        currentFileRef = ref(storage, `Open Banking Tracking/${latestYear}/${formattedLatestMonthFolder}/${latestMonth.toUpperCase()}_15th.csv`);
                        previousFileRef = ref(storage, `Open Banking Tracking/${latestYear}/${formattedLatestMonthFolder}/${latestMonth.toUpperCase()}_1st.csv`);
                    } else {
                        // If we're in the first half of the month
                        currentFileRef = ref(storage, `Open Banking Tracking/${latestYear}/${formattedLatestMonthFolder}/${latestMonth.toUpperCase()}_1st.csv`);
                        previousFileRef = ref(storage, `Open Banking Tracking/${latestYear}/${formattedPreviousMonthFolder}/${previousMonth.toUpperCase()}_15th.csv`);
                    }

                    // Try to fetch and parse the current file first
                    const currentUrl = await getDownloadURL(currentFileRef);
                    const currentResponse = await fetch(currentUrl);
                    const currentCsvText = await currentResponse.text();

                    let userRentData = await parseCSVAndFindUser(currentCsvText, referenceCode);
                    if (userRentData) {
                        setRentInfo(userRentData);
                        setPaymentPeriod(extractPaymentPeriod(currentFileRef.name));
                    } else {
                        // If not found in the current file, try the previous file
                        const previousUrl = await getDownloadURL(previousFileRef);
                        const previousResponse = await fetch(previousUrl);
                        const previousCsvText = await previousResponse.text();

                        userRentData = await parseCSVAndFindUser(previousCsvText, referenceCode);
                        if (userRentData) {
                            setRentInfo(userRentData);
                            setPaymentPeriod(extractPaymentPeriod(previousFileRef.name));
                        } else {
                            setError("No rent data found in the current or previous rent periods.");
                        }
                    }
                } else {
                    setError("User data not found.");
                }
            } catch (error) {
                setError("Error fetching rent data.");
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchRentData();
    }, [currentUser]);

    // Handle consent checkbox toggle
    const handleConfirmChange = (event) => {
        setConfirmPayment(event.target.checked);
    };

    // Handle the "Confirm and Pay" button click
    const handleConfirmPayment = () => {
        // Extract the part of the payment link after the last '/'
        const paymentLinkCode = rentInfo['Payment Link'].split('/').pop();
    
        // Combine the reference code with the payment link code to create the reference
        const userReference = `${rentInfo['Ref Code']}_${paymentLinkCode}`;
    
        // Open the payment link in a new tab
        window.open(rentInfo['Payment Link'], '_blank');
    
        // Redirect the user to the confirmation page in the current tab
        navigate('/payment-confirmation', {
            state: {
                rentAmount: rentInfo['Rent'],
                paymentReference: userReference // Pass the reference to the confirmation page
            }
        });
    };

    const termsOfServiceLink = () => {
        switch (region) {
            case "EU":
                return "https://truelayer.com/en-ie/enduser_tos";
            case "EU-DE":
                return "https://truelayer.com/de-de/rechtliche-hinweise/nutzungsbedingungen-fur-endbenutzer";
            case "EU-ES":
                return "https://truelayer.com/es-es/legal/condiciones-del-servicio-para-el-usuario-final/";
            case "EU-FR":
                return "https://truelayer.com/fr-fr/legal/conditions-generales-dutilisation/";
            default:
                return "https://truelayer.com/enduser_tos";
        }
    };

    const privacyPolicyLink = () => {
        switch (region) {
            case "EU":
                return "https://truelayer.com/en-ie/privacy";
            case "EU-DE":
                return "https://truelayer.com/de-de/rechtliche-hinweise/datenschutzerklarung/";
            case "EU-ES":
                return "https://truelayer.com/es-es/legal/aviso-de-privacidad/";
            case "EU-FR":
                return "https://truelayer.com/fr-fr/legal/politique-de-confidentialite/";
            default:
                return "https://truelayer.com/privacy";
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    if (!rentInfo) {
        return <Typography>No rent information available.</Typography>;
    }

    return (
        <Container maxWidth="sm">
            <Box 
                display="flex" 
                flexDirection="column" 
                alignItems="center" 
                justifyContent="flex-start" 
                sx={{ paddingTop: '50px', paddingBottom: '50px' }} // Adjusted top padding for higher positioning
            >
                <Typography variant="h4" gutterBottom>
                    Pay Rent For: {paymentPeriod} {/* Display the extracted payment period */}
                </Typography>

                {/* Payee Information */}
                <Typography variant="body1" gutterBottom>
                    You are paying {rentInfo.PayeeName || "Linehan O'Brien Investments Ltd"}.
                </Typography>

                {/* Amount and Currency */}
                <Typography variant="body1" gutterBottom>
                    Amount: €{rentInfo['Rent']} EUR
                </Typography>

                {/* Consent Form */}
                <FormControlLabel
                    control={<Checkbox checked={confirmPayment} onChange={handleConfirmChange} />}
                    label="I confirm that I want to proceed with this payment."
                    sx={{ marginTop: '20px' }}
                />

                {/* Confirm Payment Button */}
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleConfirmPayment} // Trigger both actions: open payment link and navigate
                    disabled={!confirmPayment} // Disable button until user confirms
                    sx={{ marginTop: '20px' }}
                >
                    Confirm and Pay
                </Button>

                {/* Terms and Privacy Policy */}
                <Typography variant="body2" align="center" sx={{ marginTop: '30px', padding: '0 10px' }}>
                    By continuing you are permitting TrueLayer to initiate a payment from your bank account. 
                    You also agree to our{' '}
                    <Link href={termsOfServiceLink()} target="_blank" rel="noopener noreferrer">Terms of Service</Link> and{' '}
                    <Link href={privacyPolicyLink()} target="_blank" rel="noopener noreferrer">Privacy Policy</Link>.
                </Typography>
            </Box>
        </Container>
    );
}

export default PayRent;
