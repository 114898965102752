import React from 'react';
import { Container, Grid, Box, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing
import BathroomIcon from '@mui/icons-material/Bathtub';
import KitchenIcon from '@mui/icons-material/Kitchen';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import SecurityIcon from '@mui/icons-material/Security';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SettingsIcon from '@mui/icons-material/Settings';
import FireplaceIcon from '@mui/icons-material/Fireplace';
import WaterIcon from '@mui/icons-material/Water';
import HouseIcon from '@mui/icons-material/House';

function ReportIssue() {
  const navigate = useNavigate();

  const tiles = [
    { label: 'Bathroom and Toilet', icon: <BathroomIcon fontSize="large" /> },
    { label: 'Kitchen', icon: <KitchenIcon fontSize="large" /> },
    { label: 'Heating and Boiler', icon: <FireplaceIcon fontSize="large" /> },
    { label: 'Water and Leaks', icon: <PlumbingIcon fontSize="large" /> },
    { label: 'Doors, Garages, and Locks', icon: <SecurityIcon fontSize="large" /> },
    { label: 'Internal floors, walls, and ceilings', icon: <HouseIcon fontSize="large" /> },
    { label: 'Electricity', icon: <ElectricalServicesIcon fontSize="large" /> },
    { label: 'Hot Water', icon: <WaterIcon fontSize="large" /> },
    { label: 'Alarms and Smoke Detectors', icon: <NotificationsNoneIcon fontSize="large" /> },
    { label: 'Other', icon: <SettingsIcon fontSize="large" /> },
  ];

  const handleTileClick = (label) => {
    navigate(`/report/${label}`); 
  };

  return (
    <Container maxWidth="md" sx={{ paddingY: { xs: 2, md: 4 } }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ fontSize: { xs: '1.8rem', md: '2.5rem' } }}>
        Report an Issue
      </Typography>
      <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
        {tiles.map((tile) => (
          <Grid item xs={12} sm={6} md={4} key={tile.label}>
            <Paper
              elevation={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: { xs: 1, sm: 2 },
                cursor: 'pointer',
                height: '100%',
                justifyContent: 'center',
                textAlign: 'center',
              }}
              onClick={() => handleTileClick(tile.label)}
            >
              <Box>{tile.icon}</Box>
              <Typography variant="h6" sx={{ marginTop: { xs: 1, sm: 1.5 }, fontSize: { xs: '1rem', md: '1.25rem' } }}>
                {tile.label}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default ReportIssue;